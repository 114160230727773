<template>
  <div>
    <vs-popup class="sm:popup-w-80" title="Tambah Media" :active="isActive" v-on:update:active="emitModalIsActive">
      <div>
        <div class="vx-row">
          <div class="vx-col w-full">
            <ValidationErrors :errors="errors"/>
          </div>
        </div>
        <div class="vx-row mb-3">
          <div class="vx-col sm:w-4/12 w-full">
            <label class="ml-1 text-xs">Proyek</label>
            <v-select :options="proyeks" label="nama" :reduce="item => item.id" :clearable="true" v-model="data.id_proyek"/>
          </div>
          <div class="vx-col sm:w-4/12 w-full">
            <label class="ml-1 text-xs">Platform *</label>
            <div @click="modalMediaPlatform.active = true">
              <vx-input-group>
                <vs-input placeholder="Pilih Platform" :value="data.nama_platform" readonly/>
                <template slot="append">
                  <div class="append-text btn-addon">
                    <vs-button type="filled" icon-pack="feather" icon="icon-search"/>
                  </div>
                </template>
              </vx-input-group>
            </div>
          </div>
          <div class="vx-col sm:w-4/12 w-full">
            <label class="ml-1 text-xs">Judul *</label>
            <vs-input class="w-full" v-model="data.judul"/>
          </div>
        </div>
        <div class="vx-row mb-3" v-if="!isPlatformWhatsapp">
          <div class="vx-col sm:w-1/1 w-full">
            <label class="ml-1 text-xs">Files<span class="text-xs opacity-75" v-if="isCompressingFiles">(compressing...)</span></label>
            <vs-input class="w-full" type="file" accept="image/*,video/*" multiple v-model="data.filesTmp" @change="onSelectedFiles"/>
          </div>
        </div>
        <div class="vx-row mb-3">
          <div class="vx-col sm:w-1/1 w-full">
            <label class="ml-1 text-xs">Deskripsi *</label>
            <template v-if="data.is_support_html_content_platform">
              <div class="h-64 mb-24">
                <quill-editor ref="quillEditor" class="h-full" :class="{'opacity-50 pointer-events-none': isUploading}" v-model="data.deskripsi" :options="editorOptions"/>
                <input ref="quillEditorFile" id="inputFile" type="file" accept="image/*" class="hidden" @change="uploadImage($event)"/>
              </div>
            </template>
            <template v-else>
              <vs-textarea class="w-full" icon-pack="feather" icon="icon-map-pin" v-model="data.deskripsi"/>
            </template>
          </div>
        </div>
        <div class="vx-row float-right">
          <div class="vx-col w-full">
            <vs-button class="mr-3" type="border" @click="emitModalIsActive(false)">Batal</vs-button>
            <vs-button :disabled="isLoading || isCompressingFiles" @click="save">
              <span v-if="isLoading" class="animate-pulse">Menyimpan...</span>
              <span v-if="!isLoading">Simpan</span>
            </vs-button>
          </div>
        </div>
      </div>

      <!--modals-->
      <vs-popup class="sm:popup-w-60 popup-content-no-padding animate-none"
                title="Pilih Platform"
                :active="modalMediaPlatform.active"
                v-on:update:active="modalMediaPlatform.active = $event">
        <MediaPlatform v-if="!isFirstActive" :selectable="true" @selected="onSelectedMediaPlatform"/>
      </vs-popup>

    </vs-popup>
  </div>
</template>

<script>
import MediaRepository from '@/repositories/marketing/media-repository'
import ProyekRepository from '@/repositories/master/proyek-repository'
import FilesRepository from '@/repositories/general/files-repository'
import ValidationErrors from '@/views/components/validation-errors/ValidationErrors'
import MediaPlatform from '@/views/pages/master/media-platform/MediaPlatform'
import vSelect from 'vue-select'
import _ from 'lodash'
import 'flatpickr/dist/flatpickr.css'
import { compressImage, convertToFormData } from '@/utilities/common/global-methods'
import Quill from 'quill'
import { quillEditor } from 'vue-quill-editor'
import ImageResize from 'quill-image-resize'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
Quill.register('modules/imageResize', ImageResize)

export default {
  name: 'MediaAdd',
  props: ['isActive'],
  components: {
    MediaPlatform,
    ValidationErrors,
    'v-select': vSelect,
    quillEditor
  },
  watch: {
    isActive (active) {
      if (active && this.isFirstActive) {
        this.getAllProyek()
        this.isFirstActive = false
      }
    }
  },
  computed: {
    isPlatformWhatsapp () {
      return this.data.nama_platform === 'WhatsApp'
    }
  },
  data () {
    return {
      isFirstActive: true,
      isLoading: false,
      isCompressingFiles: false,
      isUploading: false,
      errors: null,
      modalMediaPlatform: {
        active: false
      },
      data: {},
      proyeks: [],
      editorOptions: {
        theme: 'snow',
        modules: {
          toolbar: {
            container: [
              ['bold', 'italic', 'underline', 'strike'],
              ['blockquote', 'code-block'],
              [{ header: 1 }, { header: 2 }],
              [{ list: 'ordered' }, { list: 'bullet' }],
              [{ script: 'sub' }, { script: 'super' }],
              [{ indent: '-1' }, { indent: '+1' }],
              [{ direction: 'rtl' }],
              [{ size: ['small', false, 'large', 'huge'] }],
              [{ header: [1, 2, 3, 4, 5, 6, false] }],
              [{ color: [] }, { background: [] }],
              [{ font: [] }],
              [{ align: [] }],
              ['clean'],
              ['image', 'video']
            ],
            handlers: {
              image: function () {
                document.getElementById('inputFile').click()
              }
            }
          },
          imageResize: {
            modules: ['Resize', 'DisplaySize', 'Toolbar']
          }
        }
      }
    }
  },
  methods: {
    async onSelectedFiles (event) {
      const files = event.target.files
      this.isCompressingFiles = true
      this.data.files = await this.compressFiles(files)
      this.isCompressingFiles = false
    },

    async compressFiles (files) {
      const filesCompressed = []
      for (const file of files) {
        const isImageFile = !!file.type.match('image.*')
        const fileCompressed = isImageFile ? (await compressImage(file)) : file
        filesCompressed.push(fileCompressed)
      }
      return filesCompressed
    },

    getAllProyek () {
      const params = {
        filter: JSON.stringify({
          only_authorized_user: 1
        })
      }
      ProyekRepository.getAll(params)
        .then(response => {
          this.proyeks = response.data.data
        })
        .catch(error => {
          console.log(error)
        })
    },

    save () {
      this.errors = null
      this.isLoading = true

      const params = convertToFormData(this.buildParams())
      MediaRepository.create(params)
        .then(response => {
          this.onSuccess()
        })
        .catch(error => {
          console.log(error)
          if (error.response.status === 422) {
            this.errors = error.response.data.errors
          } else {
            this.notifyError('Terjadi kesalahan.')
          }
        })
        .finally(() => {
          this.isLoading = false
        })
    },

    async uploadImage (e) {
      const files = e.target.files
      this.isUploading = true

      const params = convertToFormData({
        files: await this.compressFiles(files)
      })
      FilesRepository.upload(params)
        .then(response => {
          const imageUrl = response.data.files_url[0]
          this.pushImageToEditor(imageUrl)
        })
        .catch(error => {
          console.log(error)
          if (error.response.status === 422) {
            this.errors = error.response.data.errors
          } else {
            this.notifyError('Terjadi kesalahan.')
          }
        })
        .finally(() => {
          this.$refs.quillEditorFile.value = null
          this.isUploading = false
        })
    },

    pushImageToEditor (imageUrl) {
      const quill = this.$refs.quillEditor.quill
      const range = quill.getSelection()
      quill.insertEmbed(range.index, 'image', imageUrl)
    },

    buildParams () {
      return {
        id_proyek: this.data.id_proyek,
        id_media_platform: this.data.id_media_platform,
        judul: this.data.judul,
        deskripsi: this.data.deskripsi,
        files: this.data.files
      }
    },

    onSelectedMediaPlatform (data) {
      this.data.id_media_platform = data.id
      this.data.tipe_platform = data.tipe
      this.data.nama_platform = data.platform
      this.data.is_support_html_content_platform = data.is_support_html_content
      this.modalMediaPlatform.active = false
    },

    onSuccess () {
      this.notifySuccess('Data berhasil disimpan.')
      this.resetData()
      this.emitIsSuccess(true)
      this.emitModalIsActive(false)
    },

    resetData () {
      const exceptObjects = _.pick(this.$data, ['proyeks'])
      const newData = Object.assign(this.$options.data.call(this), exceptObjects)
      Object.assign(this.$data, newData)
    },

    emitIsSuccess (isSuccess) {
      this.$emit('success', isSuccess)
    },

    emitModalIsActive (isActive) {
      this.$emit('update:isActive', isActive)
    }
  }
}
</script>
